import Cookie from './http/cookie';
import Data from './http/data';

/**
 * @memberOf BX
 */
export default class Http
{
	static Cookie = Cookie;
	static Data = Data;
}